import { mediacoreApi } from '@apis/mediacoreApi'
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn } from '@reduxjs/toolkit/query'
import {
  Action,
  ApplicationTableRow,
  ClientTableRow,
  Communication,
  Contact,
  GenericProperty,
  Investor,
  InvestorNews,
  News,
  Owner,
  Position,
  PropertyTableRow,
  SiteV2Page,
  SupportTicket,
  TableQueryParams,
  TableResponse,
  TokkoProperties,
  User,
  UserCommunication,
  VisitRecordRow,
  ProductTableRow,
  Resource,
} from '@types'

const tableQuery =
  (url: string) =>
  ({
    clientId: client,
    search,
    sorted,
    filter,
    activeTab,
    ids,
    ids_objects,
    validate_type,
    owners,
    communications,
    is_active,
    limit,
    offset,
  }: TableQueryParams) => ({
    url,
    method: 'GET',
    params: {
      format: 'json',
      client,
      search: search ?? '',
      owners,
      communications,
      is_active,
      ids,
      ids_objects: ids_objects && JSON.stringify(ids_objects),
      validate_type,
      sorted: `${sorted?.direction === 'desc' ? '-' : ''}${sorted?.key ?? ''}`,
      limit,
      offset,
      ...filter,
      ...(activeTab === 'active' ? { is_active: 'true' } : activeTab === 'inactive' ? { is_active: 'false' } : {}),
    },
  });
const tablesApi = mediacoreApi.injectEndpoints({
  endpoints: (builder: EndpointBuilder<BaseQueryFn, string, string>) => ({
    getCommunicationTable: builder.query<TableResponse<Communication>, TableQueryParams>({
      query: tableQuery('/owner/comunicaciones/'),
      providesTags: ['CommunicationTable'],
    }),
    getOwnerCommunicationTable: builder.query<TableResponse<UserCommunication>, TableQueryParams>({
      query: tableQuery('/owner/comunicaciones/propias/'),
      providesTags: ['UserCommunicationTable'],
    }),
    getInvestorCommunicationTable: builder.query<TableResponse<UserCommunication>, TableQueryParams>({
      query: tableQuery('/investor/comunicaciones/propias/'),
      providesTags: ['UserCommunicationTable'],
    }),
    getVisitRecordTable: builder.query<TableResponse<VisitRecordRow>, TableQueryParams>({
      query: tableQuery('/owner/registro-visitas/'),
      providesTags: ['VisitRecordTable'],
    }),
    getInvestorNewsTable: builder.query<TableResponse<InvestorNews>, TableQueryParams>({
      query: tableQuery('/investor/novedades/'),
      providesTags: ['InvestorNewsTable'],
    }),
    getBlogNewsTable: builder.query<TableResponse<News>, TableQueryParams>({
      query: tableQuery('/blog/novedades/'),
      providesTags: ['BlogNewsTable'],
    }),
    getOwnerTable: builder.query<TableResponse<Owner>, TableQueryParams>({
      query: tableQuery('/owner/propietarios/'),
      providesTags: ['OwnerTable'],
    }),
    getInvestorTable: builder.query<TableResponse<Investor>, TableQueryParams>({
      query: tableQuery('/investor/inversores/'),
      providesTags: ['InvestorTable'],
    }),
    getApplicationTable: builder.query<TableResponse<ApplicationTableRow>, TableQueryParams>({
      query: tableQuery('/jobs/aplicaciones/'),
      providesTags: ['ApplicationTable'],
    }),
    getPositionTable: builder.query<TableResponse<Position>, TableQueryParams>({
      query: tableQuery('/jobs/posiciones/'),
      providesTags: ['PositionTable'],
    }),
    getTicketTable: builder.query<TableResponse<SupportTicket>, TableQueryParams>({
      query: tableQuery('/core/soporte/'),
      providesTags: ['TicketTable'],
    }),
    getClientTable: builder.query<TableResponse<ClientTableRow>, TableQueryParams>({
      query: tableQuery('/core/clientes/'),
      providesTags: ['ClientTable'],
    }),
    getUserTable: builder.query<TableResponse<User>, TableQueryParams>({
      query: tableQuery('/core/usuarios/'),
      providesTags: ['UserTable'],
    }),
    getActionTable: builder.query<TableResponse<Action>, TableQueryParams>({
      query: tableQuery('/push/acciones/'),
      providesTags: ['ActionTable'],
    }),
    getPageTable: builder.query<TableResponse<SiteV2Page>, TableQueryParams>({
      query: tableQuery('/site/sitios-pages/'),
      providesTags: ['PageTable'],
    }),
    getPropertyMixTable: builder.query<TableResponse<Partial<GenericProperty>>, TableQueryParams>({
      query: tableQuery('/owner/propiedades-mix/'),
      providesTags: ['PropertyMixTable'],
    }),
    getPropertyTable: builder.query<TableResponse<PropertyTableRow>, TableQueryParams>({
      query: tableQuery('/site/propiedades/'),
      providesTags: ['PropertyTable'],
    }),
    getTokkoPropertyTable: builder.query<TableResponse<TokkoProperties>, TableQueryParams>({
      query: tableQuery('/owner/tokko-propiedades/'),
      providesTags: ['TokkoPropertyTable'],
    }),
    getContactsTable: builder.query<TableResponse<Contact>, TableQueryParams>({
      query: tableQuery('/site/contactos/'),
      providesTags: ['ContactTable'],
    }),
    getProductTable: builder.query<TableResponse<ProductTableRow>, TableQueryParams>({
      query: tableQuery('/site/productos/'),
      providesTags: ['ProductTable'],
    }),
    getResourceTable: builder.query<TableResponse<Resource>, TableQueryParams>({
      query: tableQuery('/agency/recursos/'),
      providesTags: ['ResourceTable'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetCommunicationTableQuery,
  useGetVisitRecordTableQuery,
  useGetInvestorNewsTableQuery,
  useGetBlogNewsTableQuery,
  useGetOwnerCommunicationTableQuery,
  useGetInvestorCommunicationTableQuery,
  useGetOwnerTableQuery,
  useGetInvestorTableQuery,
  useGetApplicationTableQuery,
  useGetPositionTableQuery,
  useGetTicketTableQuery,
  useGetClientTableQuery,
  useGetUserTableQuery,
  useGetActionTableQuery,
  useGetPageTableQuery,
  useGetPropertyMixTableQuery,
  useGetPropertyTableQuery,
  useGetTokkoPropertyTableQuery,
  useGetContactsTableQuery,
  useGetProductTableQuery,
  useGetResourceTableQuery,
} = tablesApi
