import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getAllByClientIdQuery,
  getByIdAndClientIdQuery,
  pkSubEndpointMutation,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { GalleryPayload, News, NewsTag } from '@types'
const newsEndpointURL = 'blog/novedades'
const tagsEndpointURL = 'blog/novedades-tags'

const newsApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getNewsById: builder.query<News, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery(newsEndpointURL),
      providesTags: (_result, _error, { id }) => [{ type: 'News', id }],
    }),
    deleteNews: builder.mutation<void, { id: number; clientId: number }>({
      query: deleteByIdMutation(newsEndpointURL),
      invalidatesTags: ['BlogNewsTable'],
    }),
    createNews: builder.mutation<News, { data: Partial<News> }>({
      query: createOneMutation(newsEndpointURL),
      invalidatesTags: ['BlogNewsTable'],
    }),
    updateNews: builder.mutation<News, { id: number; data: Partial<News> }>({
      query: updateByIdMutation(newsEndpointURL),
      invalidatesTags: ['BlogNewsTable'],
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedNews } = await queryFulfilled
          dispatch(
            newsApi.util.updateQueryData('getNewsById', { id, clientId: updatedNews.client }, (draft) => {
              Object.assign(draft, updatedNews)
            }),
          )
        } catch {}
      },
    }),
    getTags: builder.query<NewsTag[], { clientId: number }>({
      query: getAllByClientIdQuery(tagsEndpointURL),
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: 'Tags' as const, id })), 'Tags'] : ['Tags'],
    }),
    getTagById: builder.query<NewsTag, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery(tagsEndpointURL),
      providesTags: (_result, _error, { id }) => [{ type: 'Tags', id }],
    }),
    deleteTag: builder.mutation<void, { id: number; clientId: number }>({
      query: deleteByIdMutation(tagsEndpointURL),
      async onQueryStarted({ id, clientId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          dispatch(
            newsApi.util.updateQueryData('getTags', { clientId }, (draftTags) => {
              return draftTags.filter((draftTag) => draftTag.id !== id)
            }),
          )
        } catch {}
      },
    }),
    createTag: builder.mutation<NewsTag, { data: Partial<NewsTag> }>({
      query: createOneMutation(tagsEndpointURL),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: createdTag } = await queryFulfilled
          dispatch(
            newsApi.util.updateQueryData('getTags', { clientId: createdTag.client }, (draftTags) => {
              draftTags.push(createdTag)
              return draftTags
            }),
          )
        } catch {}
      },
    }),
    updateTag: builder.mutation<NewsTag, { id: number; data: Partial<NewsTag> }>({
      query: updateByIdMutation(tagsEndpointURL),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedTag } = await queryFulfilled
          dispatch(
            newsApi.util.updateQueryData('getTagById', { id, clientId: updatedTag.client }, (draft) => {
              Object.assign(draft, updatedTag)
            }),
          )
          dispatch(
            newsApi.util.updateQueryData('getTags', { clientId: updatedTag.client }, (draftTags) => {
              return draftTags.map((draftTag) => (draftTag.id !== id ? draftTag : updatedTag))
            }),
          )
        } catch {}
      },
    }),
    uploadNewsImages: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation(newsEndpointURL, 'galeria'),
      invalidatesTags: ['News', 'BlogNewsTable'],
    }),
    uploadNewsFiles: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation(newsEndpointURL, 'galeria_archivos'),
      invalidatesTags: ['News', 'BlogNewsTable'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetNewsByIdQuery,
  useLazyGetNewsByIdQuery,
  useDeleteNewsMutation,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useGetTagsQuery,
  useGetTagByIdQuery,
  useDeleteTagMutation,
  useCreateTagMutation,
  useUpdateTagMutation,
  usePrefetch: usePrefetchBlog,
  useUploadNewsImagesMutation,
  useUploadNewsFilesMutation,
} = newsApi
