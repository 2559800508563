import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getAllByClientIdQuery,
  getByIdQuery,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { User } from '@types'

const usersApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserById: builder.query<User, { id: number }>({
      query: getByIdQuery('core/usuarios'),
      providesTags: (_result, _error, { id }) => [{ type: 'User', id }],
    }),
    deleteUser: builder.mutation<void, { id: number }>({
      query: deleteByIdMutation('core/usuarios'),
      invalidatesTags: ['UserTable'],
    }),
    createUser: builder.mutation<User, { data: Partial<User> }>({
      query: createOneMutation('core/usuarios'),
      invalidatesTags: ['UserTable'],
    }),
    updateUser: builder.mutation<User, { id: number; data: Partial<User> }>({
      query: updateByIdMutation('core/usuarios'),
      invalidatesTags: ['User', 'UserTable'], // A permission patch may change the list of users
    }),
    inviteUser: builder.mutation<User, { email: string; client: number }>({
      query: ({ email, client }: { email: string; client: number }) => ({
        url: `core/usuarios/invite/`,
        method: 'POST',
        body: { email, client },
      }),
      invalidatesTags: ['User', 'UserTable'],
    }),
    getUsersByClientPermissions: builder.query<User[], { clientId: number }>({
      query: getAllByClientIdQuery('core/usuarios/brokers'),
      providesTags: ['User'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUserByIdQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  usePrefetch: usePrefetchUsers,
  useInviteUserMutation,
  useGetUsersByClientPermissionsQuery,
} = usersApi
