import { mediacoreApi } from '@apis/mediacoreApi'
import {
  createOneMutation,
  deleteByIdMutation,
  getAllByClientIdQuery,
  getByIdAndClientIdQuery,
  pkSubEndpointMutation,
  updateByIdMutation,
} from '@apis/mediacoreQueries'
import { GalleryPayload, Resource } from '@types'

const resourcesUrl = '/agency/recursos'
const restrictedResourcesUrl = '/agency/recursos-restringidos'

const resourcesApi = mediacoreApi.injectEndpoints({
  endpoints: (builder) => ({
    getResourceById: builder.query<Resource, { id: number; clientId: number }>({
      query: getByIdAndClientIdQuery(resourcesUrl),
      providesTags: (_result, _error, { id }) => [{ type: 'Resource', id }],
    }),
    getResources: builder.query<Resource[], { clientId: number }>({
      query: getAllByClientIdQuery(resourcesUrl),
      providesTags: ['Resource'],
    }),
    createResource: builder.mutation<Resource, { data: Partial<Resource> }>({
      query: createOneMutation(resourcesUrl),
      invalidatesTags: ['ResourceTable'],
    }),
    createRestrictedResource: builder.mutation<Resource, { data: Partial<Resource> }>({
      query: createOneMutation(restrictedResourcesUrl),
      invalidatesTags: ['ResourceTable'],
    }),
    deleteResource: builder.mutation<void, { id: number; clientId: number }>({
      query: deleteByIdMutation(resourcesUrl),
      invalidatesTags: ['ResourceTable'],
    }),
    updateResource: builder.mutation<Resource, { id: number; data: Partial<Resource> }>({
      query: updateByIdMutation(resourcesUrl),
      invalidatesTags: ['Resource', 'ResourceTable', 'ResourceEvent'],
    }),
    updateRestrictedResource: builder.mutation<Resource, { id: number; data: Partial<Resource> }>({
      query: updateByIdMutation(restrictedResourcesUrl),
      invalidatesTags: ['Resource', 'ResourceTable', 'ResourceEvent'],
    }),
    uploadResourceFiles: builder.mutation<unknown, { id: number; data: GalleryPayload }>({
      query: pkSubEndpointMutation(resourcesUrl, 'galeria_archivos'),
      invalidatesTags: ['Resource', 'ResourceTable', 'ResourceEvent'],
    }),
    uploadResourceReportFacebookPostImage: builder.mutation<
      unknown,
      { id: number; data: { file: File; uuid: string } }
    >({
      query: pkSubEndpointMutation(resourcesUrl, 'galeria_informe_facebook_posts'),
      invalidatesTags: ['Resource', 'ResourceTable', 'ResourceEvent'],
    }),
    uploadResourceReportFacebookStoryImage: builder.mutation<
      unknown,
      { id: number; data: { file: File; uuid: string } }
    >({
      query: pkSubEndpointMutation(resourcesUrl, 'galeria_informe_facebook_historias'),
      invalidatesTags: ['Resource', 'ResourceTable', 'ResourceEvent'],
    }),
    uploadResourceReportInstagramInsightImage: builder.mutation<
      unknown,
      { id: number; data: { file: File; uuid: string } }
    >({
      query: pkSubEndpointMutation(resourcesUrl, 'galeria_informe_instagram_insights'),
      invalidatesTags: ['Resource', 'ResourceTable', 'ResourceEvent'],
    }),
  }),
  overrideExisting: false,
})
export const {
  useGetResourceByIdQuery,
  useLazyGetResourceByIdQuery,
  useGetResourcesQuery,
  useCreateResourceMutation,
  useUpdateResourceMutation,
  useUploadResourceFilesMutation,
  useDeleteResourceMutation,
  useCreateRestrictedResourceMutation,
  useUpdateRestrictedResourceMutation,
  useUploadResourceReportFacebookPostImageMutation,
  useUploadResourceReportFacebookStoryImageMutation,
  useUploadResourceReportInstagramInsightImageMutation,
} = resourcesApi
